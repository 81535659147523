import { Col } from 'jsxstyle/preact'
import { h } from 'preact'
import { App } from './components/App'
import { Navbar } from './components/Navbar'
import { render } from './util/loadPage'

const LoginPage = () => {
  return (
    <App>
      <header>
        <Navbar />
        <h1>Login</h1>
      </header>
      <form action="/login" method="post">
        <label>
          Email:
          <input type="text" name="email" />
        </label>
        <label>
          Password:
          <input type="password" name="password" />
        </label>
        <input type="submit">Sign up</input>
      </form>
    </App>
  );
}


render(<LoginPage />);
